hr { 
    display: block; 
    margin: 1em 0; 
    padding: 0;
    height: 1px; 
    border: 0; 
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #fff; 
}

// Figures and images
// --------------------------------------------------
figure {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  @include clearfix();
  img {
    margin-bottom: 10px;
  }
  a { 
    img {
      @include translate(0, 0);
      -webkit-transition-duration: 0.25s;
      -moz-transition-duration: 0.25s;
      -o-transition-duration: 0.25s;
      &:hover {
        @include translate(0, -5px);
        @include box-shadow(0 0 10px fade($base-color, 20));
      }
    }
  }
  &.half {
    @media #{$large} {
      img {
        width: 310px;
        float: left;
        margin-right: 10px;
      }
      figcaption {
        clear: left;
      }
    }
  }
  &.third {
    @media #{$large} {
      img {
        width: 200px;
        float: left;
        margin-right: 10px;
      }
      figcaption {
        clear: left;
      }
    }
  }
}
svg:not(:root) {
  overflow: hidden; 
}

// Buttons
// --------------------------------------------------
.btn {
  display: inline-block;
  margin-bottom: 20px;
  padding: 8px 20px;
  @include font-rem(14);
  background-color: $primary;
  color: $white;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: $primary;
  @include rounded(3px);
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $primary;
  }
}
.btn-inverse {
  background-color: $white;
  color: $primary;
  border-color: $white;
  &:visited {
    color: $primary;
  }
  &:hover {
    background-color: $primary;
    color: $white;
  }
}
.btn-success {
  background-color: $success;
  color: $white;
  border-color: $success;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $success;
  }
}
.btn-warning {
  background-color: $warning;
  color: $white;
  border-color: $warning;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $warning;
  }
}
.btn-danger {
  background-color: $danger;
  color: $white;
  border-color: $danger;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $danger;
  }
}
.btn-info {
  background-color: $info;
  color: $white;
  border-color: $info;
  &:visited {
    color: $white;
  }
  &:hover {
    background-color: $white;
    color: $info;
  }
}

// Well
// --------------------------------------------------
.well {
  padding: 20px;
  border: 1px solid $comp-color;
  @include rounded(4px);
}