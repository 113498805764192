.gist {

  .highlight {
    color:#000;
  }

  .render-container .render-viewer-error,
  .render-container .render-viewer-fatal,
  .render-container .octospinner {
    display:none;
  }

  .gist-render iframe {
    width:100%;
  }

  .gist-file.gist-render .highlight {
    border:none;
  }

  .gist-file .gist-meta .highlight a {
    font-weight:700;
    color:#666;
    text-decoration:none;
  }

  .highlight {
    background:#fff;
  }

  .highlight .err {
    color:#a61717;
    background-color:#e3d2d2;
  }

  .highlight .cp {
    color:#999;
    font-weight:700;
  }

  .highlight .cs {
    color:#999;
    font-weight:700;
    font-style:italic;
  }

  .highlight .gd {
    color:#000;
    background-color:#fdd;
  }

  .highlight .gd .x {
    color:#000;
    background-color:#faa;
  }

  .highlight .ge {
    color:#000;
    font-style:italic;
  }

  .highlight .gi {
    color:#000;
    background-color:#dfd;
  }

  .highlight .gi .x {
    color:#000;
    background-color:#afa;
  }

  .highlight .go {
    color:#888;
  }

  .highlight .gs {
    font-weight:700;
  }

  .highlight .gu {
    color:#aaa;
  }

  .highlight .nb {
    color:#0086b3;
  }

  .highlight .ni {
    color:purple;
  }

  .highlight .nt {
    color:navy;
  }

  .highlight .w {
    color:#bbb;
  }

  .highlight .sr {
    color:#009926;
  }

  .highlight .ss {
    color:#990073;
  }

  .highlight .c,
  .highlight .cm,
  .highlight .c1 {
    color:#998;
    font-style:italic;
  }

  .highlight .k,
  .highlight .o,
  .highlight .kc,
  .highlight .kd,
  .highlight .kp,
  .highlight .kr,
  .highlight .ow,
  .highlight .n,
  .highlight .p {
    color:#000;
    font-weight:700;
  }

  .highlight .gr,
  .highlight .gt {
    color:#a00;
  }

  .highlight .gh,
  .highlight .bp {
    color:#999;
  }

  .highlight .gp,
  .highlight .nn {
    color:#555;
  }

  .highlight .kt,
  .highlight .nc {
    color:#458;
    font-weight:700;
  }

  .highlight .m,
  .highlight .mf,
  .highlight .mh,
  .highlight .mi,
  .highlight .mo,
  .highlight .il {
    color:#099;
  }

  .highlight .s,
  .highlight .sb,
  .highlight .sc,
  .highlight .sd,
  .highlight .s2,
  .highlight .se,
  .highlight .sh,
  .highlight .si,
  .highlight .sx,
  .highlight .s1 {
    color:#d14;
  }

  .highlight .na,
  .highlight .no,
  .highlight .nv,
  .highlight .vc,
  .highlight .vg,
  .highlight .vi {
    color:teal;
  }

  .highlight .ne,
  .highlight .nf {
    color:#900;
    font-weight:700;
  }

}